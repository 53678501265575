import { Injectable } from "@angular/core";
import * as mixpanel from "mixpanel-browser";
import { environment } from "@ds-env/environment";
import { IUser } from "@ds-shared/models/common.model";

@Injectable({
	providedIn: "root"
})
export class MixpanelService {
	/**
	 * Initialize mixpanel.
	 *
	 * @param {string | number} userToken
	 * @memberof MixpanelService
	 */
	init(userToken?: string | number, user?: IUser): void {
		if (environment.APIENDPOINT.includes("digitalshelf.anchanto.com")) {
			mixpanel.init("a7d04f6f8af61351012e262297d6abca");
			if (userToken) {
				mixpanel.identify(userToken.toString());
				mixpanel.people.set({
					$name: user.first_name,
					$email: user.email
				});
			}
		}
	}
	/**
	 * Push new action to mixpanel.
	 *
	 * @param {string} id Name of the action to track.
	 * @param {*} [action={}] Actions object with custom properties.
	 * @memberof MixpanelService
	 */
	track(id: string, action: any = {}): void {
		if (environment.APIENDPOINT.includes("digitalshelf.anchanto.com")) {
			mixpanel.track(id, action);
		}
	}
	/**
	 * Reset Mixpanel
	 */
	logout() {
		if (environment.APIENDPOINT.includes("digitalshelf.anchanto.com")) {
			mixpanel.reset();
		}
	}
}
